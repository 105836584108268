<template>
  <div class="min-h-screen bg-green-500">
    <TnHeader />

    <div
      class="mx-auto min-h-[calc(100vh-var(--header-height))] max-w-7xl px-0 py-0 sm:px-6 sm:py-6 lg:px-8"
    >
      <slot />
    </div>

    <TnFooter class="pb-[150px] sm:pb-6" />
  </div>
</template>
